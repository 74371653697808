<template>
  <div>
    <div class="content-base-price-raw">
      <div class="content-title-text">
        <p class="p-title">Precio base actual</p>
        <p class="p-text">
          <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
        </p>
      </div>
      <div class="content-btns-actions ml-2">
        <div class="content-btn-new-base-price">
          <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {

  },
};
</script>
<style scoped>
.content-base-price-raw {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}
.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}
.p-title {
  color: var(--primary-color-text-title-detail);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: start;
}
.p-text {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 47px;
  text-align: start;
  margin-bottom: 0px;
}

.content-title-text {
  margin-bottom: 15px;
  width: 100%;
}

.content-btns-actions {
  display: flex;
  justify-content: end;
  align-self: center;

  margin-top: 15px;
}
.content-btn-close {
  width: 200px;
  margin-right: 15px;
}
.content-btn-new-base-price {
  width: 170px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-base-price-raw {
    display: block;
    width: 100%;
    margin-bottom: 0px;
  }
  .content-btn-new-base-price {
    width: 100%;
    display: block;
    text-align: -webkit-center;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>